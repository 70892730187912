<template>
	<div class="tabs-container-large">
		<div class="tabs-wrapper">
			<CTabs :active-tab="0">
				<CTab title="General info">
					<div class="main-wrapper pt-4">
						<FormMarketingCampaignGeneralInfo
							:is-submitting="create.isCreating"
							@onConfirm="handleCreate"
						/>
					</div>
				</CTab>
			</CTabs>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import FormMarketingCampaignGeneralInfo from '@/components/FormMarketingCampaignGeneralInfo.vue';
import { convertDateTimeToUTC } from '../assets/js/helpers';

export default {
	name: 'MarketingCampaignCreate',
	components: {
		FormMarketingCampaignGeneralInfo,
	},
	computed: {
		...mapState('marketingCampaigns', {
			create: 'create',
		}),
	},
	methods: {
		...mapActions({
			createMarketingCampaign: 'marketingCampaigns/createMarketingCampaign',
		}),
		async handleCreate(formData) {
			const params = {
				name_th: formData.generalInfo.nameTh,
				name_en: formData.generalInfo.nameEn,
				slug: formData.generalInfo.slug,
				is_active: !!formData.generalInfo.isActive,
				is_visible: !!formData.generalInfo.isVisible,
				start_at: formData.activeDate.startDate
					? convertDateTimeToUTC(formData.activeDate.startDate, formData.activeDate.startTime)
					: null,
				end_at: formData.activeDate.endDate
					? convertDateTimeToUTC(formData.activeDate.endDate, formData.activeDate.endTime)
					: null,
				seo: {
					title_th: formData.seo.titleTh,
					title_en: formData.seo.titleEn,
					description_th: formData.seo.descriptionTh,
					description_en: formData.seo.descriptionEn,
					keyword_th: formData.seo.keywordTh,
					keyword_en: formData.seo.keywordEn,
				},
				description_top: formData.content.descriptionTop,
				description_bottom: formData.content.descriptionBottom,
				banners: formData.content.banners
					? formData.content.banners
						.filter((banner) => banner)
						.map((banner) => banner.id)
					: [],
				widgets: formData.linkToHomepage.widgets
					? formData.linkToHomepage.widgets.map((widget) => widget.id)
					: [],
			};
			await this.createMarketingCampaign(params);
		},
	},
};
</script>
